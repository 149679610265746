import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { Slide } from 'react-awesome-reveal'
import Boy from '../assets/contactimage.png'
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneVolume } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

function Contact() {
    const fire = (message, type) => {
        enqueueSnackbar(message, {
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'right ',
                horizontal: 'right'
            }, variant: type
        })
    }
    const [data, setdata] = useState({
        name: '',
        mail: '',
        message: ''
    })
    const [error, seterror] = useState('')
    const sendTo = () => {

        for (const key in data) {
            if (data[key] == '') {
                seterror('0')
                console.log('cc');
                return;
            }

        }
        console.log('ok');

        fetch('https://backend.shesharide.co.za/api/contact_web', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data sent successfully:', data);
                if (data.message == 'success') {
                    fire('success', 'success')
                    handleShowModal()
                }

            })
            .catch(error => {
                console.error('Error sending data:', error);
                fire('Something Went Wrong!!', 'error')
                // Handle error as needed
            });
        setdata({
            name: '',
            mail: '',
            message: ''
        })

    }
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <div>
            <SnackbarProvider
                autoHideDuration={5000}
            />
            <section className='grid grid-cols-1   md:grid-cols-2 lg:grid-cols-2  items-start lg:items-start overflow-hidden'  >

                <Slide direction='left' triggerOnce >
                    <div className="">
                        <img src={Boy} alt="" className='' />
                    </div>
                </Slide>
                <Slide direction='right' className='' triggerOnce>
                    <div className=" md:p-5 p-5 lg:p-10">
                        <h3 className='text-black text-2xl md:text-2xl lg:text-4xl font-extrabold uppercase lg:ms-0'>

                            We're here to help  <br />   <span className='text-[#f62f02]'> get in touch</span>
                        </h3>
                        <p className='lg:text-2xl lg:my-2 ps-0 p-5 leading-8 lg:leading-10'>
                            Whether you have a question, need support, or just want to give feedback, we're here to listen. Reach out today and let's make your Shesha experience better, together.
                        </p>
                        <div className="text-lg lg:text-2xl p-2 py-2 md:pb-0 lg:py-5">
                        <div className="p-4 bg-gray-100 rounded-lg shadow-md mx-auto">
                            <div className="mb-4 flex items-center space-x-2">
                                <IoLocationSharp className="text-blue-600 text-lg" />
                                <p className="text-gray-700 font-medium">
                                <span className="font-bold">Office Address:</span> 4 Pieter Wenning Rd, Fourways, Sandton 2191
                                </p>
                            </div>
                            <div className="mb-4 flex items-center space-x-2">
                                <FaPhoneVolume className="text-green-600 text-lg" />
                                <p className="text-gray-700 font-medium">
                                <span className="font-bold ">Office Line:</span> 010 157 8680
                                </p>
                            </div>
                            <div className="mb-4 flex items-center space-x-2">
                                <MdMarkEmailUnread className="text-red-600 text-lg" />
                                <p className="text-gray-700 font-medium">
                                <span className="font-bold">Email:</span> info@shesharide.co.za
                                </p>
                            </div>
                            <div className="flex items-center space-x-2">
                                <IoLogoWhatsapp className="text-green-500 text-lg" />
                                <p className="text-gray-700 font-medium">
                                <span className="font-bold">WhatsApp:</span> 061 453 2260 / 071 042 0019
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </Slide>


            </section>
            
        </div>
    )
}

export default Contact