import React, { useEffect } from 'react';

const DriverMenuWidget = () => {
    useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.simplybook.me/v2/widget/widget.js';
    script.async = true;

    script.onload = () => {
      window.SimplybookWidget = new window.SimplybookWidget({
        widget_type: 'iframe',
        url: 'https://sheshaehailingservices.simplybook.me',
        theme: 'blur',
        theme_settings: {
          timeline_hide_unavailable: '1',
          hide_past_days: '0',
          timeline_show_end_time: '0',
          timeline_modern_display: 'as_slots',
          sb_base_color: '#f60406',
          display_item_mode: 'block',
          body_bg_color: '#fafafa',
          dark_font_color: '#171717',
          light_font_color: '#ffffff',
          btn_color_1: '#333233',
          sb_company_label_color: '#333233',
          hide_img_mode: '0',
          sb_busy: '#f60406',
          sb_available: '#171717',
        },
        timeline: 'modern',
        datepicker: 'top_calendar',
        is_rtl: false,
        app_config: {
          clear_session: 0,
          allow_switch_to_ada: 0,
          predefined: [],
        },
        container_id: 'sbw_1ys1go',
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
      <div id="sbw_1ys1go"></div>
  );
};

export default DriverMenuWidget;
