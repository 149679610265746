import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import ResI from '../assets/Add Stop.png';
import app from '../assets/App Security.png';
import car from '../assets/Car Safety.png';
import emp from '../assets/Emergency Line.png';
import Fair from '../assets/Fixed Prices.png';
import carsec from '../assets/Identity.png';
import Trans from '../assets/S-Wallet.png';
import Safe from '../assets/Safety.png';
import share from '../assets/Share ride.png';
import HomeTop from '../assets/WhyChooseUs.png';
import Business from '../assets/businessman.png';
import srtP from '../assets/cusStripe.png';
import DriverR from '../assets/empR.png';
import rate from '../assets/interest-rate.png';
import sec from '../assets/security.png';
import safetyAndSecurity from '../assets/safetyAndSecurity.png';
import reliabilityAndConvenience from '../assets/reliabilityAndConvenience.png';
import driverBackgroundChecks from '../assets/driverBackgroundChecks.png';
import realTimeTracking from '../assets/realTimeTracking.png';
import appSafetyFeatures from '../assets/appSafetyFeatures.png';
import identityVerification from '../assets/identityVerification.png';
import safetyTrainingforDrivers from '../assets/safetyTrainingforDrivers.png';
import transparentCommunication from '../assets/transparentCommunication.png';
import regulatoryCompliance from '../assets/regulatoryCompliance.png';
import protectComputers from '../assets/protectComputers.png';





function ForCustomer() {
    const card = [
        {
            img: safetyAndSecurity,
            head: 'Safety and Security',
            p1: "We need our customers to feel safe during their rides. Features like real-time tracking, driver background checks, and in-app Help buttons are highly valued.",
            p2: ""

        }, {
            img: reliabilityAndConvenience,
            head: 'Reliability and Convenience',
            p1: " ",
            p2: "We provide the ability to quickly and easily book a ride, along with accurate estimated arrival times and a dependable service. For us it is crucial for our customers to appreciate a seamless and hassle-free experience."

        },
        {
            img: Trans,
            head: 'Affordability',
            p1: "",
            p2: "Competitive pricing and transparent fare structures are important, so we offer Customers with preferred services that offer good value for money without hidden fees."

        },
        {
            img: Safe,
            head: 'Exceptional Customer Service',
            p1: "We have responsive and helpful customer support that can make a big difference. Whether it’s resolving issues or answering questions, our excellent customer service enhances the overall experience.",

        }
    ]
    const bottomCard = [
        {
            img: driverBackgroundChecks,
            head: 'Driver Background Checks',
            p1: "We conduct background checks on all drivers, including criminal record checks and driving history, to ensure they are trustworthy and reliable.",

        },
        {
            img: realTimeTracking,
            head: 'Real-Time Tracking',
            p1: "We enable real-time GPS tracking of rides so customers and their loved ones can monitor the journey. This feature provides peace of mind and helps in quickly locating the vehicle in case of emergencies.",

        },
        {
            img: appSafetyFeatures,
            head: 'In-App Safety Features',
            p1: "We’ve incorporated features like the Help button and emergency contacts that allow customers to alert authorities or emergency contacts instantly.Additionally, options to share trip details with friends or family can enhance safety",

        }, {
            img: identityVerification,
            head: 'Identity Verification',
            p1: "We ensure that both drivers and passengers verify their identities through the app. This can include photo verification and linking accounts to official identification documents.",

        }, {
            img: safetyTrainingforDrivers,
            head: 'Safety Training for Drivers',
            p1: "We provide drivers with training on safety protocols, customerservice, and how to handle emergencies. This helps maintain a high standard of service and safety.",

        }, {
            img: transparentCommunication,
            head: 'Transparent Communication',
            p1: "We keep customers informed about their driver’s details,estimated arrival times, and any changes to the trip. Clear communication helps build trust and ensures a smoother experience.",

        },
        {
            img: regulatoryCompliance,
            head: 'Regulatory Compliance',
            p1: "We Adhere to local regulations and work with authorities to ensure that all safety standards are met. This includes obtaining necessary permits and following guidelines set by transport authorities.",

        },
        {
            img: protectComputers,
            head: 'Cybersecurity Measures',
            p1: "Our platform protects user data with robust cybersecurity protocols to prevent breaches and ensure that personal information remains confidential.",

        }
    ]

    return (
        <div>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.6) 500px 5000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10 tracking-wider  font-extrabold">
                        <Fade delay={500} triggerOnce duration={200} cascade className='text-3xl md:text-6xl lg:text-7xl font-bold text-white '>
                        Ride With

                        </Fade>
                        <Fade delay={1500} triggerOnce duration={500} cascade className='text-3xl ms-2  md:text-6xl lg:text-7xl font-bold text-[#f62f02] '>
                            Us

                        </Fade>
                    </div>
                </Slide>
            </div>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 flex justify-center items-center " style={{ backgroundImage: `url(${Business})`, boxShadow: 'rgba(0, 0, 0, 0.2) 500px 5000px inset', }} >
                <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 place-items-start py-16 px-5 lg:px-0 place-content-start gap-5 lg:gap-16 ">
                    {card.map((e, i) => {
                        return (
                            <Fade delay={i * 150} direction='up' triggerOnce>
                                <div className="card relative max-w-[35rem] border-2  lg:p-5 md:p-3 p-2 my-5 lg:my-0 min-h-[200px] lg:min-h-[auto]   rounded-lg bg-white shadow-2xl">
                                    <div className="absolute -top-8 lg:-top-14 -left-4">
                                        <img src={e.img} alt="" className='p-2 lg:p-4 text-center h-[50px] md:h-[60px] lg:h-[80px] bg-[#f62f02] rounded-full object-fill ' />
                                    </div>
                                    <div className="text-left pt-8 space-y-4 p-2">
                                        <strong className='pb-2 font-extrabold text-sm lg:text-lg'>{e.head}</strong>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg'>{e.p1}</p>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg '>{e.p2}</p>


                                    </div>

                                </div>
                            </Fade>
                        )
                    })}
                </div>
            </div>
            {/* emeergency ride */}
            <section className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2  items-start lg:items-start '  >

                <Slide direction='left' triggerOnce style={{ background: `url(${srtP}) `, backgroundSize: 'contain', backgroundPositionY: '-30%', backgroundPositionX: '-5pc', backgroundRepeat: 'no-repeat' }}>
                    <div className="">
                        <img src={DriverR} alt="" className='' />
                    </div>
                </Slide>
                <Slide direction='right' className='mt-20' triggerOnce>
                    <div className="p-3 lg:py-9">
                        <h3 className='text-black text-2xl md:text-3xl lg:text-4xl font-extrabold  lg:ms-0'>

                        Key Features   <span className='text-black'> On Our App</span>
                        </h3>
                        <div className="text-lg lg:text-2xl p-3 py-4 md:pb-0 lg:py-8 space-y-8">
                            <h3 className='text-black text-2xl md:text-3xl lg:text-3xl font-extrabold py-1  lg:py-2'>
                            S    <span className='text-[#f62f02]'>-Wallet</span> 
                            </h3>
                            <p className='leading-8 lg:leading-10'>Our S-Wallet helps you save for future trips by allowing you to budget and plan
                            effectively with the cash saved in your Shesha Wallet.</p>
                            <h3 className='text-black text-2xl md:text-3xl lg:text-3xl font-extrabold py-1  lg:py-2'>

                            Emergency <span className='text-[#f62f02]'>Ride </span>
                            </h3>
                            <p className='leading-8 lg:leading-10'>We offer emergency rides for those critical moments when you need a
ride but don’t have cash on hand.</p>
                            <h3 className='text-black text-2xl md:text-3xl lg:text-3xl font-extrabold py-1  lg:py-2'>
                            Help<span className='text-[#f62f02]'> Button </span>
                            </h3>
                            <p className='leading-8 lg:leading-10'>Our help button provides immediate support, whether during your ride or before and after. Our Shesha agents are always available to give you the information you need.</p>
                            <h3 className='text-black text-2xl md:text-3xl lg:text-3xl font-extrabold py-1  lg:py-2'>
                            OTP<span className='text-[#f62f02]'> Verification</span> Help<span className='text-[#f62f02]'> Button </span>
                            </h3>
                            <p className='leading-8 lg:leading-10'>Shesha is proud to be the first app in South Africa to introduce a robust OTP (One-Time Password) security feature, enhancing customer safety. Before starting a trip, the customer provides the driver with a unique OTP. The trip will only commence if the customer is in the correct car with the designated driver. If the OTP does not match, the trip will not start, ensuring the customer is always in the right vehicle with the right driver.</p>
                        </div>


                    </div>
                </Slide>


            </section>
            {/* final section */}
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 flex-col flex justify-center items-center " style={{ backgroundImage: `url(${sec})`, boxShadow: 'rgba(0, 0, 0, 0.2) 500px 5000px inset', }} >
                <h3 className='text-white text-2xl md:text-3xl lg:text-4xl font-extrabold py-1  lg:py-5  uppercase'>
                    <span className='text-[#f62f02]'> Safety </span> & security
                </h3>
                <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 place-items-start py-16 px-5 lg:px-0 place-content-start gap-5 lg:gap-16 ">
                    {bottomCard.map((e, i) => {
                        return (
                            <Fade delay={i * 150} direction='up' triggerOnce>
                                <div className="card relative max-w-[35rem] border-2  lg:p-5 md:p-3 p-2 my-5 lg:my-0 min-h-[200px] lg:min-h-[auto]   rounded-lg bg-white shadow-2xl">
                                    <div className="absolute -top-8 lg:-top-14 -left-4">
                                        <img src={e.img} alt="" className='p-2 lg:p-4 text-center h-[50px] md:h-[60px] lg:h-[80px] bg-[#f62f02] rounded-full object-fill ' />
                                    </div>
                                    <div className="text-left pt-8 space-y-4 p-2">
                                        <strong className='pb-2 font-extrabold text-sm lg:text-lg'>{e.head}</strong>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg'>{e.p1}</p>
                                        <p className='font-medium text-xs md:text-sm lg:text-lg '>{e.p2}</p>
                                    </div>
                                </div>
                            </Fade>
                        )
                    })}


                </div>
            </div>
        </div>
    )
}

export default ForCustomer