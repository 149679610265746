import React, { useEffect } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import aP from '../assets/apple-l.png';
import Logo from '../assets/footerlogo.png';
import Gp from '../assets/gp.png';
import { FaWhatsapp } from "react-icons/fa";

function Footer() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    return (
        <>
        <div className='w-full bg-black flex justify-center flex-col items-center lg:p-10 p-5 overflow-hidden'>
            <div className='w-[100%] flex justify-around items-stretch gap-3 lg:p-10 p-5 overflow-hidden'>
                <div className="flex lg:pb-5 md:pb-3  text-center cursor-pointer md:block hidden">
                    <img src={Logo} alt="" className='h-[80px] lg:h-[120px] align-top w-full object-cover' onClick={() => navigate('/')} />
                </div>
                <div className="flex gap-20">
                    <div className="">
                        <p className='font-bold text-[#f62f02] text-center pb-2'>QUICK LINKS</p>
                        <ul className='list-none text-white font-bold text-center space-y-1'>
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/AboutUs')}>ABOUT US</li>
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/ForDrivers')}>FOR DRIVER</li>
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/ForCustomer')}>FOR CUSTOMER</li>
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/Gallery')}>GALLLERY</li>
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/Blog')}>BLOG</li>
                            <li className="cursor-pointer hover:text-[#f62f02] uppercase" onClick={() => navigate('/Privacy')}>Privacy Policy</li>
                            {/* <li className="cursor-pointer hover:text-[#f62f02] uppercase" onClick={() => navigate('/DeleteAccount')}>Delete Account</li> */}
                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/Contact')}>CONTACT US</li>
                            <li className="cursor-pointer hover:text-[#f62f02] uppercase" onClick={() => navigate('/TermsofUse')}>Terms of Use</li>

                            <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate('/Faq')}>FAQ</li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col text-center">
                        <p className='font-bold text-[#f62f02] align-top pb-2'>FOLLOW US</p>
                        <div className="grid md:grid-cols-5 grid-cols-3 gap-4 text-4xl gap-5 pt-2">
                            <a className='decoration-none' target='_blank' href=' https://www.instagram.com/shesha_ride/'>
                                <FaInstagram className='text-white' />
                            </a>
                            <a href="https://twitter.com/SheshaRide" target='_blank'>    <FaXTwitter className='text-white' /></a>
                            <a href="https://www.linkedin.com/company/shesha-ride" target='_blank'>     <FaLinkedin className='text-white' /></a>
                            <a href="https://www.facebook.com/profile.php?id=61557588747674" target='_blank'> <FaFacebookF className='text-white' /></a>
                            <a href="https://www.tiktok.com/@shesha.ride" target='_blank'>    <FaTiktok className='text-white' /></a>
                        </div>
                    </div>

            </div>
            <div className="flex lg:my-0 my-10">
                <a target='_blank' href="https://apps.apple.com/za/app/shesha-customer/id6648782162">
                    <img src={aP} alt="" className='md:h-[85px]  h-[40px] ' />
                </a>
                <a target='_blank' href="https://play.google.com/store/apps/details?id=com.shesha.rider.elite&hl=en&gl=US">

                    <img src={Gp} alt="" className='md:h-[85px] h-[40px]' />
                </a>

            </div>

        </div>
        </>
    )
}

export default Footer