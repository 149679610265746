import React, { useRef, Suspense, lazy } from 'react';
import HomeTop from '../assets/capturedOurMovements.jpeg';
import { Fade, Slide } from 'react-awesome-reveal';
import str5 from '../assets/strip2.png';
import { FaArrowRight } from "react-icons/fa6";
import str4 from '../assets/stripe1.png';
import goodMemorieslast from '../assets/goodMemorieslast.png';
import ourClientsSay from '../assets/ourClientsSay.jpeg';
import { FaStar } from "react-icons/fa";
import Events from "./data/Events.js"
import HandOver from "./data/HandOver.js"
import Launch from "./data/Launch.js"
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../App.css' 
function Gallery() {

    const sheshaEvents = useRef(null);
    const sheshaLaunch = useRef(null);
    const vehicleHandover = useRef(null);
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };




    const LetPressPlay = lazy(() => import('./GalleryPage/LetsPressPlay.js'));
    const GSwiper = lazy(() => import('./GalleryPage/Swiper.js'));

    return (
        <>
            <div>
                <div className="lg:min-h-[90vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${HomeTop})`,boxShadow: 'rgba(0, 0, 0, 0.6) 500px 5000px inset',}} >
                    <Slide direction='down' triggerOnce>
                        <div className="text-center pt-10 tracking-wider  font-extrabold">
                            <Fade delay={300} triggerOnce duration={100} cascade className='text-3xl md:text-6xl lg:text-7xl font-bold text-white '>
                                We captured our
                            </Fade><br></br>
                            <Fade delay={1000} triggerOnce duration={400} cascade className='text-3xl ms-2  md:text-6xl lg:text-7xl font-bold text-[#f62f02] '>
                                Movements
                            </Fade>
                        </div>
                    </Slide>
                </div>
            </div>
            <section className="bg-cover bg-no-repeat" style={{ backgroundImage: `url(${str5})`, backgroundPositionY: '30%',}}>
                {/* lg:min-h-[90vh] min-h-[50vh] */}
                <div className="text-center tracking-widest  md:py-10 py-5">  {/* font-extrabold */}
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-2xl md:text-5xl lg:text-5xl text-Black'>
                        Let’s reflect on our happy
                    </Fade><br></br>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='text-2xl ms-2  md:text-5xl lg:text-5xl font-bold text-[#f62f02] '>
                        Movements
                    </Fade>
                </div>
                <div className="bg-black p-8">
                    <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-10 gap-2'>
                        <Fade delay={100}>
                            <div className="rounded-lg shadow-md overflow-hidden">
                                <div className="p-4">
                                    <div className='bg-[red] p-2 rounded-lg'>
                                        <img src={Events[0].imgEvents} alt="our happy Moments" className='w-[100%] xl:h-[400px] md:h-[250px] h-[200px] object-cover rounded-lg' />
                                        <div className=" text-white font-bold text-lg py-2 flex justify-between items-center px-2">
                                            Shesha Events
                                            <button className='bg-[white] text-black p-2 rounded-full' onClick={() => scrollToSection(sheshaEvents)}>{<FaArrowRight />}</button>
                                        </div>
                                    </div>
                                    <div className="p-4 text-white text-lg">
                                        <Fade delay={100}>
                                            Shesha Events often showcases vibrant and
                                            dynamic photography that captures the
                                            essence of celebrations and gatherings.
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg shadow-md overflow-hidden">
                                <div className="p-4">
                                    <div className='bg-[red] p-2 rounded-lg'>
                                        <img src={Launch[0].imgEvents } alt="our happy Moments" className='w-[100%] xl:h-[400px] md:h-[250px] h-[200px] object-cover rounded-lg' />
                                        <div className=" text-white font-bold text-lg py-2 flex justify-between items-center px-2">
                                            Shesha launch
                                            <button className='bg-[white] text-black p-2 rounded-full' onClick={() => scrollToSection(sheshaLaunch)}>{<FaArrowRight />}</button>
                                        </div>
                                    </div>
                                    <div className="p-4 text-white text-lg">
                                        <Fade delay={100}>
                                            Shesha E-hailing Services held a media
                                            launch in April 2024 to signify the new-found
                                            spirit of cooperation and unity in the transport
                                            industry.
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg shadow-md overflow-hidden">
                                <div className="p-4">
                                    <div className='bg-[red] p-2 rounded-lg'>
                                        <img src={HandOver[0].imgEvents} alt="our happy Moments" className='w-[100%] xl:h-[400px] md:h-[250px] h-[200px] object-cover rounded-lg' />
                                        <div className=" text-white font-bold text-lg py-2 flex justify-between items-center px-2">
                                            Vehicle Handover
                                            <button className='bg-[white] text-black p-2 rounded-full' onClick={() => scrollToSection(vehicleHandover)}>{<FaArrowRight />}</button>
                                        </div>
                                    </div>
                                    <div className="p-4 text-white text-lg">
                                        <Fade delay={100}>
                                            We celebrated more than just the keys to an
                                            incredible partnership ; we honored the
                                            dreams, hard work, and passion that brought
                                            this moment to life.
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <Suspense fallback={<div>Loading....</div>}>
                <LetPressPlay />
            </Suspense>
            <div className="bg-no-repeat min-h-[50vh] bg-cover bg-no-repeat bg-black flex flex-col justify-evenly items-center" ref={sheshaEvents} style={{ backgroundImage: `url(${str4})`, backgroundPositionY: '40%' }}>
                <div className='text-center text-3xl font-extrabold md:py-14 py-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-2xl md:text-5xl lg:text-5xl text-white'>
                        Shesha
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='text-2xl ms-2  md:text-5xl lg:text-5xl font-bold text-[#f62f02] '>
                        Events
                    </Fade>
                </div>
                <div className='text-white lg:text-2xl text-lg text-center font-bold xl:px-[27%] xl:py-8 px-2 py-2'>
                    Shesha's approach goes beyond mere entertainment; it fosters a sense of
                    community and belonging that lingers in the minds of the our supporters,
                    making each event a cherished memory worth reflecting on.
                </div>
                <div className='w-[90%] py-8'>
                    <Suspense fallback={<div>Loading....</div>}>
                        <GSwiper data={Events} />
                    </Suspense>
                </div>
            </div>
            <div className="bg-no-repeat min-h-[50vh] bg-cover bg-no-repeat bg-black flex flex-col justify-evenly items-center" ref={sheshaLaunch} style={{ backgroundImage: `url(${str4})`, backgroundPositionY: '40%' }}>
                <div className='text-center text-3xl font-extrabold md:py-14 py-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-2xl md:text-5xl lg:text-5xl text-white'>
                        Shesha
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='text-2xl ms-2  md:text-5xl lg:text-5xl font-bold text-[#f62f02] '>
                        Launch
                    </Fade>
                </div>
                <div className='text-white lg:text-2xl text-lg text-center font-bold xl:px-[27%] xl:py-8 px-2 py-2'>
                    The Launch was a canvas for our new beginning and great collaboration
                    between the Taxi and E-hailing transport industry.
                </div>
                <div className='w-[90%] py-8'>
                    <Suspense fallback={<div>Loading....</div>}>
                        <GSwiper data={Launch} />
                    </Suspense>
                </div>
            </div>
            <div className="bg-no-repeat min-h-[50vh] bg-cover bg-no-repeat bg-black flex flex-col justify-evenly items-center" ref={vehicleHandover} style={{ backgroundImage: `url(${str4})`, backgroundPositionY: '40%' }}>
                <div className='text-center text-3xl font-extrabold md:py-14 py-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-2xl md:text-5xl lg:text-5xl text-white'>
                        Vehicle
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='text-2xl ms-2  md:text-5xl lg:text-5xl font-bold text-[#f62f02] '>
                        Handover
                    </Fade>
                </div>
                <div className='text-white lg:text-2xl text-lg text-center font-bold xl:px-[27%] xl:py-8 px-2 py-2'>
                    Beautiful moments that symbolize new beginnings and shared journeys. This
                    simple yet profound act marks the start of countless adventures, memories,
                    and milestones to come—an embodiment of hope, excitement, and the open
                    road ahead.
                </div>
                <div className='w-[90%] py-8'>
                    <Suspense fallback={<div>Loading....</div>}>
                        <GSwiper data={HandOver} />
                    </Suspense>
                </div>

            </div>
            <section
             className='bg-no-repeat lg:min-h-[90vh] bg-cover bg-no-repeat flex flex-col justify-evenly gap-3' style={{
                backgroundImage: `url(${ourClientsSay})`,
                // backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                width: "100%",
                // height: "90vh", 
            }}
            
            >
                <div className='text-center md:indent-5 indent-2 md:p-2 p-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-3xl lg:text-5xl font-bold text-white'>
                        What Our
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='text-3xl lg:text-5xl font-bold text-[#f62f02]'>
                        Clients Say
                    </Fade>
                </div>
                <div className='grid lg:grid-cols-3 grid-cols-1 md:gap-2 gap-5 place-items-center p-2'>
                    <div className='bg-[red] w-[80%] h-[100%] md:p-5 p-2 rounded-md'>
                        <div className='md:text-2xl text-lg flex md:py-3 py-2 text-white gap-3'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='text-white md:w-[95%]'>
                            The app is actually good, very safe,
                            they ask for all your details so that
                            they know who they are dealing with
                            Incase anything happens to you.
                        </div>
                        <div className='text-white font-black md:pt-3 pt-2'>
                            Bonolo Maodi
                        </div>
                    </div>
                    <div className='bg-[red] w-[80%] h-[100%] md:p-5 p-2 rounded-md'>
                        <div className='md:text-2xl text-lg flex md:py-3 py-2 text-white gap-3'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='text-white md:w-[95%]'>
                            Thanks South Africa for giving us our
                            very own app. Am certified and glad to
                            use this app because it's so easy and
                            also like the fact that it's cashless
                            payment. I urge South Africans to use
                            the app as it's our very own.
                        </div>
                        <div className='text-white font-black md:pt-3 pt-2'>
                            Lethu Ndlovu
                        </div>
                    </div>
                    <div className='bg-[red] w-[80%] h-[100%] md:p-5 p-2 rounded-md'>
                        <div className='md:text-2xl text-lg flex md:py-3 py-2 text-white gap-3'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='text-white md:w-[95%]'>
                            Great initiative and kudos to our local
                            people, foe giving us something that
                            we can all be proud of. My first ride
                            was nice,safe, and loud all the way.
                            Amazing driver I got.
                        </div>
                        <div className='text-white font-black md:pt-3 pt-2'>
                            Chanttel Van Leu
                        </div>
                    </div>
                </div>
            </section>
            <div 
            className="bg-no-repeat bg-cover bg-no-repeat bg-white md:flex md:items-center md:justify-between bg-custom" style={{ backgroundImage: `url(${str5})` }}
            >
                <div className='md:w-[1000px] w-[100%] md:p-0 p-4 md:indent-5 indent-2 grow-1 flex justify-end'>
                    <div className='md:px-14 px-7 w-[80%]'>
                        <Fade delay={300} triggerOnce duration={100} cascade className='md:text-3xl text-lg xl:text-6xl font-semibold text-black'>
                            Making good
                        </Fade><br></br>
                        <Fade delay={1000} triggerOnce duration={400} cascade className='md:text-4xl text-xl xl:text-7xl font-extrabold text-[#E20102]'>
                            Memories
                        </Fade>
                        <Fade delay={300} triggerOnce duration={100} cascade className='md:text-3xl text-lg xl:text-6xl font-semibold text-black'>
                            last
                        </Fade>
                    </div>
                </div>
                <div className='md:h-[500px]  grow-0'>
                    {/* <div className='bg-contain bg-no-repeat'style={{
                    backgroundImage: `url(${goodMemorieslast})`
                    ,height: "100%"}}>
                    </div> */}
                    <img src={goodMemorieslast} className='object-cover w-[100%] h-[100%]' />
                </div>
            </div>
        </>
    )
}

export default Gallery;